import { UseQueryOptions } from "@tanstack/react-query";

import { getAllScreeners } from "@/app/api/screeners";
import { useAuthedQuery } from "@/hooks/queries/use-authed-query.hook";
import type { AllScreenersResponse } from "@/types/screeners";

const SCREENER_QUERY_KEY_BASE = "screener";
const SCREENER_QUERY_KEYS = {
  base: [SCREENER_QUERY_KEY_BASE] as const,
};

export const useAllScreenersQuery = (
  config: Partial<UseQueryOptions<AllScreenersResponse>> = {},
) => {
  return useAuthedQuery({
    queryKey: SCREENER_QUERY_KEYS.base,
    queryFn: getAllScreeners,
    ...config,
  });
};
